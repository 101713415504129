/* eslint-disable no-restricted-syntax */
import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { BRANDS, GOAT, GRAILED, FLIGHT_CLUB, ALIAS, GOAT_GROUP } from 'common/constants/brands';
import colors from 'utils/styles/colors';
import media from 'utils/styles/media';
import supportsTouch from 'utils/supports-touch';
import letterSpacing from 'utils/styles/letterSpacing';
import HomepageBrandImage from './HomepageBrandImage';
import { brandsImageIndexReducer } from '../utils/brandsImageIndexReducer';

const initialBrandsImageIndexState = {
  [GOAT]: 0,
  [GRAILED]: 0,
  [FLIGHT_CLUB]: 0,
  [ALIAS]: 0,
};

const Homepage = () => {
  const [innerHeight, setInnerHeight] = useState(null);
  const [brand, setBrand] = useState(GOAT);
  const [isVisible, setIsVisible] = useState(false);
  const [brandsImageIndexState, dispatch] = useReducer(
    brandsImageIndexReducer,
    initialBrandsImageIndexState,
  );

  useEffect(() => {
    // using window.innerHeight to account for mobile's address bar
    const onResize = () => {
      setInnerHeight(window.innerHeight);
    };

    if (!supportsTouch()) {
      window.addEventListener('resize', onResize);
    }

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTouchStart = (selectedBrand) => () => {
    setIsVisible(true);
    setBrand(selectedBrand);
  };

  const handleMouseEnter = (selectedBrand) => () => {
    if (supportsTouch()) return;

    setIsVisible(true);
    setBrand(selectedBrand);
  };

  const handleTouchEnd = (selectedBrand) => () => {
    setIsVisible(false);
    dispatch({ type: selectedBrand });
  };

  const handleMouseLeave = (selectedBrand) => () => {
    if (supportsTouch()) return;

    setIsVisible(false);
    dispatch({ type: selectedBrand });
  };

  return (
    <HomepageWrapper height={innerHeight}>
      {brand === GRAILED ? (
        <GrailedPlaceholder />
      ) : (
        <HomepageBrandImage
          brand={brand}
          isVisible={isVisible}
          brandsImageIndex={brandsImageIndexState[brand]}
          data-testid="desktop-homepage-brands"
        />
      )}
      <HomepageCopy>
        <GoatGroup
          onMouseEnter={handleMouseEnter(GOAT_GROUP)}
          onMouseLeave={handleMouseLeave(GOAT_GROUP)}
          onTouchStart={handleTouchStart(GOAT_GROUP)}
          onTouchEnd={handleTouchEnd(GOAT_GROUP)}
        >
          GOAT Group
        </GoatGroup>{' '}
        represents the leading platforms for authentic sneakers, apparel and accessories. Operating
        four distinct brands–
        <HomepageCopyLink
          href={BRANDS[GOAT].websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={handleMouseEnter(GOAT)}
          onMouseLeave={handleMouseLeave(GOAT)}
          onTouchStart={handleTouchStart(GOAT)}
          onTouchEnd={handleTouchEnd(GOAT)}
          onTouchCancel={handleTouchEnd(GOAT)}
          data-testid="goat-link"
        >
          GOAT
        </HomepageCopyLink>
        ,{' '}
        <HomepageCopyLink
          href={BRANDS[FLIGHT_CLUB].websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={handleMouseEnter(FLIGHT_CLUB)}
          onMouseLeave={handleMouseLeave(FLIGHT_CLUB)}
          onTouchStart={handleTouchStart(FLIGHT_CLUB)}
          onTouchEnd={handleTouchEnd(FLIGHT_CLUB)}
          onTouchCancel={handleTouchEnd(FLIGHT_CLUB)}
          data-testid="flight-club-link"
        >
          Flight Club
        </HomepageCopyLink>
        ,{' '}
        <HomepageCopyLink
          href={BRANDS[GRAILED].websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={handleMouseEnter(GRAILED)}
          onMouseLeave={handleMouseLeave(GRAILED)}
          onTouchStart={handleTouchStart(GRAILED)}
          onTouchEnd={handleTouchEnd(GRAILED)}
          onTouchCancel={handleTouchEnd(GRAILED)}
          data-testid="grailed-link"
        >
          Grailed
        </HomepageCopyLink>{' '}
        and{' '}
        <HomepageCopyLink
          href={BRANDS[ALIAS].websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={handleMouseEnter(ALIAS)}
          onMouseLeave={handleMouseLeave(ALIAS)}
          onTouchStart={handleTouchStart(ALIAS)}
          onTouchEnd={handleTouchEnd(ALIAS)}
          onTouchCancel={handleTouchEnd(ALIAS)}
          data-testid="alias-link"
        >
          alias
        </HomepageCopyLink>
        –GOAT Group has a global community of more than 60M members across 170 countries.
      </HomepageCopy>
    </HomepageWrapper>
  );
};

const HomepageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: ${({ height }) => `calc(${height}px - 62px)`};
  max-height: ${({ height }) => `calc(${height}px - 62px)`};
  padding: 0 30px 30px;
  background-color: ${colors.black};

  ${media['640']`
    padding: 0 60px 60px;
  `}
`;
const HomepageCopy = styled.div`
  ${letterSpacing};
  color: ${colors.white};
  margin-top: 20px;
  font-size: 24px;
  line-height: 28px;

  ${media['640']`
    font-size: 32px;
    line-height: 38px;
  `}
`;
const GoatGroup = styled.span`
  cursor: default;
`;
const HomepageCopyLink = styled.a`
  color: inherit;
  white-space: nowrap;
`;

// this will be deleted once we have a grailed image
const GrailedPlaceholder = styled.div`
  flex: 1;
  display: grid;
  min-width: 0;
  min-height: 0;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
`;

export default Homepage;
