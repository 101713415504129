import React from 'react';
import PropTypes from 'prop-types';
import colors from 'utils/styles/colors';

const Hamburger = ({ fill = colors.black }) => (
  <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill={fill}>
      <path d="M0 16h24v-2H0zM0 9h24V7H0zM0 2h24V0H0z" />
    </g>
  </svg>
);

Hamburger.propTypes = {
  fill: PropTypes.string,
};
Hamburger.defaultProps = {
  fill: colors.white,
};

export default Hamburger;
