import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import media from 'utils/styles/media';
import colors from 'utils/styles/colors';
import letterSpacing from 'utils/styles/letterSpacing';
import convertToEasternTime from 'utils/convertToEasternTime';
import { BRANDS } from 'common/constants/brands';
import { mobileGridStyles, desktopGridStyles, tabletGridStyles } from 'utils/styles/gridColumns';
import useMaxHeight from '../utils/useMaxHeight';

const NewsFeedRow = ({ article }) => {
  const [isSelected, setIsSelected] = useState(false);
  const { subject, publishTime, brand, coverPicture, slug } = article;

  const titleRef = useRef(null);
  const infoRef = useRef(null);
  const height = useMaxHeight(titleRef, infoRef);

  const handleOnMouseEnterAndTouchStart = () => {
    setIsSelected(true);
  };

  const handleOnMouseLeaveAndTouchEnd = () => {
    setIsSelected(false);
  };

  return (
    <NewsFeedRow.Wrapper
      to={`/news/${slug}`}
      height={height}
      isSelected={isSelected}
      onMouseEnter={handleOnMouseEnterAndTouchStart}
      onMouseLeave={handleOnMouseLeaveAndTouchEnd}
      onTouchStart={handleOnMouseEnterAndTouchStart}
      onTouchEnd={handleOnMouseLeaveAndTouchEnd}
      data-testid="article-row-wrapper"
    >
      <NewsFeedRow.Title ref={titleRef} isSelected={isSelected} data-testid="article-title">
        {subject}
      </NewsFeedRow.Title>
      <NewsFeedRow.DateTime
        ref={infoRef}
        isSelected={isSelected}
        data-testid="article-publish-info"
      >
        {publishTime && (
          <>
            <div data-testid="article-publish-date">
              {format(convertToEasternTime(publishTime), 'MMM d, yyyy')}
            </div>
            <div data-testid="article-publish-time">
              {`${format(convertToEasternTime(publishTime), 'h:mm')} ET`}
            </div>
          </>
        )}
      </NewsFeedRow.DateTime>
      <NewsFeedRow.Brand isSelected={isSelected} data-testid="article-brand">
        {brand && BRANDS[brand].displayName}
      </NewsFeedRow.Brand>
      <div>
        {coverPicture && isSelected && (
          <NewsFeedRow.Image
            src={coverPicture.thumbUrl}
            alt={subject}
            data-testid="article-image"
          />
        )}
      </div>
    </NewsFeedRow.Wrapper>
  );
};

const ArticleShape = PropTypes.shape({
  subject: PropTypes.string.isRequired,
  brand: PropTypes.string,
  publishTime: PropTypes.string,
  coverPicture: PropTypes.shape({ url: PropTypes.string }),
});

NewsFeedRow.propTypes = {
  article: ArticleShape.isRequired,
};

NewsFeedRow.Wrapper = styled(Link)`
  ${mobileGridStyles};
  margin-bottom: 40px;
  font-size: 16px;
  ${letterSpacing};
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: ${colors.black};
  height: ${({ height }) => `${height}px`};
  text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};

  ${media['640']`
    ${tabletGridStyles};
    font-size: 24px;
    line-height: 28px;
  `}

  ${media['1024']`
    ${desktopGridStyles};
  `}
`;
NewsFeedRow.Title = styled.div``;
NewsFeedRow.DateTime = styled.div``;
NewsFeedRow.Brand = styled.div`
  display: none;

  ${media['640']`
    display: block;
  `}
`;
NewsFeedRow.Image = styled.img`
  display: none;

  ${media['1024']`
    display: block;
    max-width: 100%;
  `}
`;

export default NewsFeedRow;
