import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import media from 'utils/styles/media';
import getDeviceByViewport from 'utils/getDeviceByViewport';
import letterSpacing from 'utils/styles/letterSpacing';
import ProgressBar from 'common/loader/components/ProgressBar';
import { fetchCmsArticles } from 'server/api/cms-articles-api';
import NewsFeedRow from './NewsFeedRow';

const ARTICLES_PER_PAGE = getDeviceByViewport() === 'mobile' ? 6 : 12;

const News = () => {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationToken, setPaginationToken] = useState('');
  const [lastPaginationToken, setLastPaginationToken] = useState('');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const cmsArticles = await fetchCmsArticles(ARTICLES_PER_PAGE, paginationToken);

        setArticles((articlesState) => [...articlesState, ...cmsArticles.articles]);

        setLastPaginationToken(cmsArticles.paginationToken);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles(paginationToken);
  }, [paginationToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLoadMore = () => {
    setPaginationToken(lastPaginationToken);
  };

  if (error) {
    return (
      <NewsWrapper>
        <div>Oops, something went wrong, please try again.</div>
      </NewsWrapper>
    );
  }

  return (
    <NewsWrapper>
      <ProgressBar isLoading={isLoading} />
      {articles.map((article) => (
        <NewsFeedRow article={article} key={article.id} data-testid="news-feed-row" />
      ))}
      {lastPaginationToken && (
        <LoadMore onClick={handleLoadMore} data-testid="load-more-articles">
          Load More
        </LoadMore>
      )}
    </NewsWrapper>
  );
};

const NewsWrapper = styled.div`
  flex: 1;
  margin: 50px 30px 80px;
  background-color: ${colors.white};

  ${media['640']`
    margin: 80px 60px 160px;
  `}
`;
const LoadMore = styled.button`
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 16px;
  color: ${colors.gray};
  cursor: pointer;
  background-color: inherit;
  border: none;
  outline: none;
  padding: 0;
  margin-top: 20px;

  ${media['640']`
    font-size: 24px;
    margin-top: 40px;
    ${letterSpacing};
  `}
`;

export default News;
