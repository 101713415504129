import React from 'react';

export const Close = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="1.3839"
      y1="0.616096"
      x2="21.3839"
      y2="20.6161"
      stroke="white"
      strokeWidth="1.08584"
    />
    <line x1="1.37824" y1="20.6139" x2="21" y2="0.992155" stroke="white" strokeWidth="1.08584" />
  </svg>
);

export default Close;
