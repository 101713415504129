import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'utils/styles/media';
import ARTICLE_SECTION_TYPES from 'common/constants/articleSectionTypes';
import FullImageSection from './FullImageSection';
import TextSection from './TextSection';

const articleTypeComponents = {
  [ARTICLE_SECTION_TYPES.FULL_IMAGE]: FullImageSection,
  [ARTICLE_SECTION_TYPES.TEXT]: TextSection,
};

const SectionsIterator = ({ sections, brand }) => (
  <SectionsWrapper>
    {sections.map((section) => {
      const { type, id, ...props } = section;
      const ArticleSectionComponent = articleTypeComponents[type];

      return <ArticleSectionComponent key={id} brand={brand} {...props} />;
    })}
  </SectionsWrapper>
);

const sectionsShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
});

SectionsIterator.propTypes = {
  sections: PropTypes.arrayOf(sectionsShape).isRequired,
  brand: PropTypes.string.isRequired,
};

const SectionsWrapper = styled.div`
  margin-bottom: 50px;

  ${media['640']`	
    margin-bottom: 60px;	
  `}
`;

export default SectionsIterator;
