import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ALIAS, BRANDS, GOAT_GROUP } from 'common/constants/brands';

const GRID_ROWS_COLUMNS = 3;
const getRandomIntFromOne = (max) => Math.floor(Math.random() * Math.floor(max)) + 1;

const HomepageBrandImage = ({ isVisible, brand, brandsImageIndex }) => {
  const selectedBrand = BRANDS[brand];
  const selectedBrandImages = selectedBrand && selectedBrand.images;
  const brandImage = selectedBrandImages[brandsImageIndex];
  const gridRowStart = useMemo(() => getRandomIntFromOne(GRID_ROWS_COLUMNS), [isVisible]);
  const gridColumnStart = useMemo(() => getRandomIntFromOne(GRID_ROWS_COLUMNS), [isVisible]);

  return (
    <HomepageBrandWrapper>
      <ImageWrapper
        gridRow={gridRowStart}
        gridCol={gridColumnStart}
        data-testid="homepage-brand-image"
      >
        {isVisible && <BrandImage src={brandImage} data-testid="brand-image" brand={brand} />}
      </ImageWrapper>
    </HomepageBrandWrapper>
  );
};

HomepageBrandImage.propTypes = {
  isVisible: PropTypes.bool,
  brand: PropTypes.string,
  brandsImageIndex: PropTypes.number,
};
HomepageBrandImage.defaultProps = {
  isVisible: false,
  brand: null,
  brandsImageIndex: 0,
};

const HomepageBrandWrapper = styled.div`
  flex: 1;
  display: grid;
  min-width: 0;
  min-height: 0;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
`;
const ImageWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  grid-row: ${({ gridRow }) => gridRow || 0};
  grid-column: ${({ gridCol }) => gridCol || 0};
`;
const BrandImage = styled.img`
  max-width: ${({ brand }) => (brand === GOAT_GROUP || brand === ALIAS ? '60%' : '100%')};
  max-height: ${({ brand }) => (brand === GOAT_GROUP || brand === ALIAS ? '60%' : '100%')};
`;

export default HomepageBrandImage;
