import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import media from 'utils/styles/media';
import colors from 'utils/styles/colors';
import AboutGoatGroup from 'common/news/components/article/AboutGoatGroup';

const Footer = () => {
  const { pathname } = useLocation();

  const isOnNewsPath = pathname.includes('news');

  return (
    <FooterWrapper isWhite={isOnNewsPath} showBorderTop={isOnNewsPath}>
      {isOnNewsPath && <AboutGoatGroup />}
      <Copyright>
        <span>&copy;{new Date().getFullYear()} </span>
        <span>1661, Inc.</span>
      </Copyright>
      <FooterLink
        href="https://www.goat.com/terms"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="terms"
      >
        Terms
      </FooterLink>
      <FooterLink
        href="https://www.goat.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="privacy"
      >
        Privacy
      </FooterLink>
      <FooterLink
        href="https://drive.google.com/drive/folders/1OBdpFudnBaZgBOaYxQxflLJtxUHskXGy"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="media"
      >
        Download Media Kit
      </FooterLink>
      <FooterLink
        href="mailto:press@goat.com?subject=Media%20Request"
        target="_blank"
        rel="noopener noreferrer"
        data-testid="press"
      >
        press@goat.com
      </FooterLink>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  background-color: ${({ isWhite }) => (isWhite ? colors.white : colors.black)};
  color: ${colors.gray};
  padding: 10px 30px;
  border-top: ${({ showBorderTop }) => showBorderTop && '1px solid rgba(153, 153, 153, 0.25)'};

  ${media['640']`
      padding: 20px 60px;
      line-height: 12px;
      justify-content: start;
   `}

  @media print {
    display: none;
  }
`;
const Copyright = styled.div`
  margin-right: 24px;
  color: inherit;
  text-transform: uppercase;
`;
const FooterLink = styled.a`
  margin-right: 24px;
  text-transform: uppercase;
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default Footer;
