import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import media from 'utils/styles/media';
import { fetchCmsArticleBySlug } from 'server/api/cms-articles-api';
import constructArticleStructuredData from 'common/news/utils/constructArticleStructuredData';
import ProgressBar from 'common/loader/components/ProgressBar';
import ArticleHeader from './ArticleHeader';
import ContentIterator from './SectionsIterator';
import ShareLinks from './ShareLinks';
import MoreRecentArticles from './MoreRecentArticles';

const NewsArticle = () => {
  const { pathname } = useLocation();
  const { articleSlug } = useParams();
  const history = useHistory();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setIsLoading(true);
        const cmsArticle = await fetchCmsArticleBySlug(articleSlug);
        setArticle(cmsArticle);
      } catch {
        history.push('/news');
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [articleSlug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [article]);

  return (
    <ArticleWrapper>
      <ProgressBar isLoading={isLoading} />
      {article && (
        <>
          <Helmet>
            <title>{`${article.subject} | GOAT Group`}</title>
            <meta name="description" content={article.metadataDescription || article.description} />
            <script type="application/ld+json">
              {`${constructArticleStructuredData(article, pathname)}`}
            </script>
          </Helmet>

          <ArticleSection>
            <ArticleHeader
              brand={article.brand}
              description={article.description}
              publishTime={article.publishTime}
              subject={article.subject}
            />
            <ShareLinks title={article.subject} />
            <ContentIterator sections={article.sections} brand={article.brand} />
            <ShareLinks title={article.subject} />
          </ArticleSection>
          <Divider />
          <MoreRecentArticles />
        </>
      )}
    </ArticleWrapper>
  );
};

const ArticleWrapper = styled.div`
  flex: 1;
  background-color: ${colors.white};
  padding: 0 30px;

  ${media['640']`
    padding: 0 60px;
  `}

  @media print {
    padding: 20px 30px;
  }
`;
const ArticleSection = styled.div`
  width: 90%;

  ${media['1024']`
    width: 58%;
  `}
`;
const Divider = styled.div`
  border-bottom: 1px solid rgba(153, 153, 153, 0.25);
  margin: 50px -30px;

  ${media['640']`
    margin: 100px -60px;
  `}
`;

export default NewsArticle;
