// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const DESKTOP_BREAKPOINT = '1024';

const ResizeContainer = ({ mobile = null, desktop = null }) => {
  const isDesktopMediaQuery = useMediaQuery({ query: `(min-width: ${DESKTOP_BREAKPOINT}px)` });

  return isDesktopMediaQuery ? desktop : mobile;
};

export default ResizeContainer;
