import React from 'react';
import PropTypes from 'prop-types';
import colors from 'utils/styles/colors';

const Download = ({ fill = colors.white }) => (
  <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8811 34.3151L27.4331 24.8076L29.9234 26.9215L15.0312 39.9337L0.0761709 26.921L2.54643 24.7716L13.1607 34.3151L13.2442 34.3901L13.2442 34.2779L13.2442 0.0499991L16.7976 0.0499993L16.7976 34.2779L16.7976 34.3903L16.8811 34.3151Z"
      fill={fill}
      stroke="black"
    />
  </svg>
);

Download.propTypes = {
  fill: PropTypes.string,
};
Download.defaultProps = {
  fill: colors.white,
};

export default Download;
