const alias = {
  BLACK: '#0e0e0e',
  GRAY: '#999999',
  WHITE: '#fcfcfc',
};

const colors = {
  black: alias.BLACK,
  gray: alias.GRAY,
  white: alias.WHITE,
};

export default colors;
