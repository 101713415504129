import { format } from 'date-fns';
import he from 'he';
import { isEmpty } from 'lodash';
import ARTICLE_SECTION_TYPES from 'common/constants/articleSectionTypes';
import convertToEasternTime from 'utils/convertToEasternTime';

export const constructArticleStructuredData = (article, pathname) => {
  if (isEmpty(article)) {
    return '';
  }

  const { subject, publishTime, sections } = article;

  const textSections = sections.filter((section) => section.type === ARTICLE_SECTION_TYPES.TEXT);
  const articleBody = textSections.map((textSection) => textSection.text).join(' ');

  const sanitizedText = he
    .decode(articleBody) // decode named and numerical character references
    .replace(/<[^>]+>/g, '') // remove html tags
    .replace(/"/g, '\\"') // escape quotes
    .replace(/\r?\n|\r/g, ' '); // remove line breaks

  const imageSections = sections.filter(
    (section) => section.type === ARTICLE_SECTION_TYPES.FULL_IMAGE,
  );
  const images = imageSections
    .map((image) => `"${image.pictures && image.pictures[0] && image.pictures[0].url}"`)
    .join(', ');

  return `{
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    "headline": "${subject}",
    "author": {
      "@type": "Organization",
      "name": "GOAT Group"
    },
    "image": [${images}],
    "datePublished": "${format(convertToEasternTime(publishTime), 'MMM d, yyyy')}",
    "articleBody": "${sanitizedText}",
    "mainEntityOfPage": "www.goatgroup.com${pathname}",
    "publisher": {
      "@type": "Organization",
      "name": "GOAT Group",
       "logo": {
         "@type": "ImageObject",
         "url": "https://www.goatgroup.com/public/goatgroup-logo.png"
       }
    }
  }`;
};

export default constructArticleStructuredData;
