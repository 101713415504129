import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from 'utils/styles/media';
import colors from 'utils/styles/colors';
import letterSpacing from 'utils/styles/letterSpacing';
import sanitizeArticleText from 'common/news/utils/sanitizeArticleText';

const TextSection = ({ text }) => (
  <Text data-testid="text-section">
    <p dangerouslySetInnerHTML={sanitizeArticleText(text)} data-testid="article-text" />
  </Text>
);

TextSection.propTypes = {
  text: PropTypes.string,
};
TextSection.defaultProps = {
  text: '',
};

const Text = styled.div`
  font-size: 24px;
  ${letterSpacing};
  margin: 30px 0;

  * {
    color: ${colors.black};
  }

  ${media['640']`
    ${letterSpacing};
    line-height: 28px;
  `}
`;
export default TextSection;
