import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CloseIcon, Hamburger } from 'common/icons';
import colors from 'utils/styles/colors';
import media from 'utils/styles/media';
import isVisibleToggle from 'utils/styles/isVisibleToggle';
import lockBodyScrolling from 'utils/lockBodyScrolling';
import ZINDEX from 'utils/styles/zIndex';
import letterSpacing from 'utils/styles/letterSpacing';

const MobileHeaderNav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const { pathname } = useLocation();
  const isOnNewsPath = pathname.includes('news');

  const openNav = () => {
    setIsNavOpen(true);
    setScrollY(window.scrollY);
  };

  const closeNav = () => setIsNavOpen(false);

  useEffect(() => {
    if (isNavOpen) {
      lockBodyScrolling(scrollY, true);
    }

    return () => {
      lockBodyScrolling(scrollY, false);
    };
  }, [isNavOpen]);

  return (
    <MobileHeaderNavWrapper>
      <HamburgerMenu onClick={openNav} data-testid="menu">
        <Hamburger fill={isOnNewsPath ? colors.black : colors.white} />
      </HamburgerMenu>
      <MenuOverlay data-testid="nav-menu" isVisible={isNavOpen}>
        <NavMenuRow>
          <StyledLink to="/news" onClick={closeNav} data-testid="news-link">
            News
          </StyledLink>
          <CloseButton onClick={closeNav} data-testid="close-button">
            <CloseIcon />
          </CloseButton>
        </NavMenuRow>
        <NavMenuRow>
          <NavLink
            href="https://boards.greenhouse.io/goatgroup"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeNav}
            data-testid="careers-link"
          >
            Careers
          </NavLink>
        </NavMenuRow>
      </MenuOverlay>
    </MobileHeaderNavWrapper>
  );
};
const MobileHeaderNavWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  @media print {
    display: none;
  }
`;
const HamburgerMenu = styled.div`
  cursor: pointer;
`;
const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 25px 30px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;

  ${media['640']`
    padding: 30px 60px;
  `}
`;
const MenuOverlay = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${ZINDEX.MENU_OVERLAY};
  padding: 48px 30px;
  background-color: ${colors.black};
  transform: ${isVisibleToggle('translateY(0)', 'translateY(-100%)')};
  opacity: ${isVisibleToggle(0.95, 0)};

  ${media['640']`
    padding: 48px 60px;
  `}
`;
const NavMenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const LinkStyles = `
  text-transform: uppercase;
  text-decoration: none;
  font-size: 24px;
  ${letterSpacing};
  line-height: 1;
  color: ${colors.white};
`;
const NavLink = styled.a`
  ${LinkStyles}
`;
const StyledLink = styled(Link)`
  ${LinkStyles}
`;

export default MobileHeaderNav;
