import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import letterSpacing from 'utils/styles/letterSpacing';

const DesktopHeaderNav = () => {
  const { pathname } = useLocation();
  const isOnNewsPath = pathname.includes('news');

  return (
    <DesktopHeaderNavWrapper>
      <StyledLinkWrapper isBlack={isOnNewsPath} isSelected={isOnNewsPath}>
        <StyledLink to="/news" data-testid="news-link">
          News
        </StyledLink>
      </StyledLinkWrapper>
      <NavLink
        href="https://boards.greenhouse.io/goatgroup"
        target="_blank"
        rel="noopener noreferrer"
        isBlack={isOnNewsPath}
        data-testid="careers-link"
      >
        Careers
      </NavLink>
    </DesktopHeaderNavWrapper>
  );
};

const DesktopHeaderNavWrapper = styled.div`
  @media print {
    display: none;
  }
`;
const LinkStyles = `
  text-transform: uppercase;
  text-decoration: none;
  font-size: 24px;
  ${letterSpacing};
  color: inherit;
  
  :hover {
    text-decoration: underline;
  }
`;
const StyledLinkWrapper = styled.span`
  color: ${({ isBlack }) => (isBlack ? colors.black : colors.white)};
  text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
`;
const StyledLink = styled(Link)`
  ${LinkStyles}
`;
const NavLink = styled.a`
  ${LinkStyles}
  color: ${({ isBlack }) => (isBlack ? colors.black : colors.white)};
  margin-left: 30px;
`;

export default DesktopHeaderNav;
