import { utcToZonedTime } from 'date-fns-tz';

const convertToEasternTime = (utcDate) => {
  const date = new Date(utcDate);
  const timeZone = 'America/New_York';

  return utcToZonedTime(date, timeZone);
};

export default convertToEasternTime;
