import axios from 'axios';
import mime from 'mime-types';

const downloadByUrl = async (urlSrc, filename) => {
  try {
    const response = await axios({
      method: 'GET',
      url: urlSrc,
      responseType: 'blob',
      headers: { Accept: '*/*' },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    let fileName = filename;

    if (!mime.lookup(fileName)) {
      fileName = `${fileName}.${mime.extension(response.data.type)}`;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.log('Error:', e);
  }
};

export default downloadByUrl;
