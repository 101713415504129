import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import supportsTouch from 'utils/supports-touch';
import media from 'utils/styles/media';
import letterSpacing from 'utils/styles/letterSpacing';

const COPY_LINK = 'Copy Link';
const LINK_COPIED = 'Copied';

const ShareLinks = ({ title }) => {
  const [copyLinkText, setCopyLinkText] = useState(COPY_LINK);
  const { pathname } = useLocation();
  const articleUrl = `www.goatgroup.com${pathname}`;
  const shareOnTwitterUrl = `https://twitter.com/intent/tweet?url=${articleUrl}&text=${title}`;
  const shareOnLinkedInUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${articleUrl}&title=${title}`;
  const isOnTouchDevice = supportsTouch();

  const handleCopyLink = () => {
    copy(articleUrl);
    setCopyLinkText(LINK_COPIED);

    setTimeout(() => {
      setCopyLinkText(COPY_LINK);
    }, 2000);
  };

  const handlePrintClick = () => {
    window.print();
  };

  return (
    <ShareLinks.Wrapper data-testid="share-links">
      <ShareLinks.Copy
        onClick={handleCopyLink}
        isOnTouchDevice={isOnTouchDevice}
        data-testid="copy-link"
      >
        {copyLinkText}
      </ShareLinks.Copy>
      <ShareLinks.LinkedIn
        as="a"
        href={shareOnLinkedInUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="share-linkedIn"
      >
        LinkedIn
      </ShareLinks.LinkedIn>
      <ShareLinks.Twitter
        as="a"
        href={shareOnTwitterUrl}
        target="_blank"
        rel="noopener noreferrer"
        isOnTouchDevice={isOnTouchDevice}
        data-testid="share-twitter"
      >
        Twitter
      </ShareLinks.Twitter>
      <ShareLinks.Print
        onClick={handlePrintClick}
        isOnTouchDevice={isOnTouchDevice}
        data-testid="print-article"
      >
        Print
      </ShareLinks.Print>
    </ShareLinks.Wrapper>
  );
};

ShareLinks.propTypes = {
  title: PropTypes.string,
};

ShareLinks.defaultProps = {
  title: '',
};

ShareLinks.Wrapper = styled.div`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  margin: 15px 0 30px;
  ${letterSpacing};

  ${media['640']`
    margin: 25px 0 50px;
  `}

  @media print {
    display: none;
    margin: 0;
  }
`;
ShareLinks.Links = styled.div`
  color: ${colors.gray};
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 30px;

  :hover {
    text-decoration: underline;
  }

  ${media['640']`
    font-size: 16px;
    ${letterSpacing};
  `}
`;
ShareLinks.Copy = styled(ShareLinks.Links)`
  order: ${({ isOnTouchDevice }) => (isOnTouchDevice ? 1 : 3)};
`;
ShareLinks.LinkedIn = styled(ShareLinks.Links)`
  order: 2;
`;
ShareLinks.Twitter = styled(ShareLinks.Links)`
  order: ${({ isOnTouchDevice }) => (isOnTouchDevice ? 3 : 1)};
`;
ShareLinks.Print = styled(ShareLinks.Links)`
  display: ${({ isOnTouchDevice }) => (isOnTouchDevice ? 'none' : 'block')};
  order: 4;
`;

export default ShareLinks;
