import axios from 'axios';

export const CMS_ARTICLES_URL = '/api/v1/cms/cms_articles';
export const CMS_ARTICLES_BY_GROUP_URL = '/api/v1/cms/cms_articles_by_group';
export const GOAT_GROUP_NEWS_CATEGORY = 'category=goat_group_news';
export const GOAT_GROUP_NEWS_GROUP = 'group=goat_group_news';
export const PUBLISHED_SORT = 'sortBy=ARTICLE_SORT_BY_PUBLISH_TIME_DESC';

export const fetchCmsArticles = (limit, paginationToken) =>
  axios
    .get(
      `${CMS_ARTICLES_BY_GROUP_URL}?${GOAT_GROUP_NEWS_GROUP}&${PUBLISHED_SORT}&limit=${limit}&paginationToken=${paginationToken}`,
    )
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(`Oops, something went wrong: ${err}`);
    });

export const fetchCmsArticleBySlug = (articleSlug) =>
  axios
    .get(`${CMS_ARTICLES_URL}/${articleSlug}?${GOAT_GROUP_NEWS_CATEGORY}`)
    .then((response) => response.data)
    .catch((err) => {
      throw new Error(`Oops, something went wrong: ${err}`);
    });

export const fetchCmsArticlesMoreRecentBySlug = (articleSlug, moreRecentCount) =>
  axios
    .get(
      `${CMS_ARTICLES_URL}/${articleSlug}/more_recent?number=${moreRecentCount}&${GOAT_GROUP_NEWS_CATEGORY}`,
    )
    .then((response) => response.data.articles)
    .catch((err) => {
      throw new Error(`Oops, something went wrong: ${err}`);
    });
