import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'utils/styles/colors';
import { Download } from 'common/icons';
import supportsTouch from 'utils/supports-touch';
import downloadByUrl from 'utils/downloadByUrl';
import appendOriginToSubdomain from 'common/news/utils/appendOriginToSubdomain';

const FullImageSection = ({ pictures, caption, credits, brand }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showDownloadArrow, setShowDownloadArrow] = useState(false);
  const showDownloadButton = !supportsTouch() && imageLoaded && showDownloadArrow;
  const image = pictures[0];
  const imageUrl = image && image.url;

  const handleOnMouseEnter = () => {
    setShowDownloadArrow(true);
  };

  const handleOnMouseLeave = () => {
    setShowDownloadArrow(false);
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const downloadImage = () => {
    if (imageUrl) {
      const downloadImageUrl = appendOriginToSubdomain(imageUrl);
      const fileName = `photo_credit_${brand}`;

      downloadByUrl(downloadImageUrl, fileName);
    }
  };

  return (
    <>
      <ImageWrapper
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        data-testid="full-image-section"
      >
        {showDownloadButton && (
          <DownloadButton data-testid="download-button" onClick={downloadImage}>
            <Download />
          </DownloadButton>
        )}
        <Image
          src={image && image.url}
          alt={caption || 'FullImage'}
          data-testid="full-image"
          onLoad={handleImageLoaded}
        />
      </ImageWrapper>
      {credits && <Credits>{credits}</Credits>}
    </>
  );
};

FullImageSection.propTypes = {
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ).isRequired,
  caption: PropTypes.string,
  credits: PropTypes.string,
  brand: PropTypes.string.isRequired,
};
FullImageSection.defaultProps = {
  caption: '',
  credits: '',
};

const DownloadButton = styled.div`
  mix-blend-mode: exclusion;
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 20px;

  @media print {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Credits = styled.div`
  text-align: right;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  color: ${colors.gray};
`;
export default FullImageSection;
