import {
  GOAT_IMAGES,
  FC_IMAGES,
  ALIAS_IMAGES,
  GOATGROUP_IMAGES,
} from 'common/homepage/components/HomepageImages';

export const GOAT = 'goat';
export const FLIGHT_CLUB = 'flight_club';
export const ALIAS = 'alias';
export const GOAT_GROUP = 'goat_group';
export const GRAILED = 'grailed';

export const BRANDS = {
  [GOAT]: {
    displayName: 'goat',
    websiteUrl: 'https://www.goat.com',
    images: GOAT_IMAGES,
    about:
      'GOAT is the global platform for style. Founded in 2015 to bring trust to the sneaker community, the technology platform has since expanded to offer apparel and accessories from the world’s leading contemporary, avant garde and luxury brands. Through its unique positioning between the primary and resale markets, the company is able to surface styles from the past, present and future, delivering authentic products to more than 60M members across 170 countries.',
  },
  [FLIGHT_CLUB]: {
    displayName: 'flight club',
    websiteUrl: 'https://www.flightclub.com',
    images: FC_IMAGES,
    about:
      'Established in New York City over 15 years ago, Flight Club revolutionized sneaker retail as the original consignment store for rare shoes. Carrying the rarest exclusives and collectible sneakers, Flight Club has evolved from a one-stop sneaker destination, to a cultural hub for sneaker enthusiasts and novices alike. With three brick-and-mortar locations in New York City, Los Angeles and Miami, Flight Club remains the premier source for authentic, rare sneakers.',
  },
  [ALIAS]: {
    displayName: 'alias',
    websiteUrl: 'https://www.alias.org',
    images: ALIAS_IMAGES,
    about:
      'Engineered to empower individuals, brands and boutiques, alias is a platform to manage and sell your products across the GOAT Group distribution channels, including retail stores, apps and websites. The platform connects sellers with buyers across the globe anonymously, making it easy to turn your valuable goods into currency.',
  },
  [GOAT_GROUP]: {
    displayName: 'goat group',
    websiteUrl: 'https://www.goatgroup.com',
    images: GOATGROUP_IMAGES,
    about:
      'GOAT Group represents the leading platforms for authentic sneakers, apparel and accessories. Operating four distinct brands– GOAT, Flight Club, Grailed and alias –GOAT Group has a global community of more than 60M members across 170 countries. The greatest selection from the past, present and future are displayed on company-operated mobile apps, e-commerce sites and retail stores. Through its innovative technology and service, GOAT Group has changed the landscape of commerce, converging the primary and resale markets.',
  },
  [GRAILED]: {
    displayName: 'grailed',
    websiteUrl: 'https://www.grailed.com/',
    images: null,
    about: ' ',
  },
};
