import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './utils/envVars'; // TODO: delete me when we have proper ENV injection
import './index.css';
import App from './App';

let ErrorBoundary = React.Fragment;

try {
  Bugsnag.start({
    apiKey: window.BUGSNAG_BROWSER_API_KEY,
    enabledReleaseStages: ['staging', 'production'],
    plugins: [new BugsnagPluginReact()],
    releaseStage: window.RELEASE_ENV,
  });

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
} catch (e) {
  console.error(e);
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);
