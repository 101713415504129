import { breakpoints } from './styles/media';

const BREAKPOINTS = {
  mobile: breakpoints[0],
  tablet: breakpoints[1],
};

export function getDeviceByViewport() {
  if (!window) return 'desktop';
  const size = window.innerWidth;
  if (size < BREAKPOINTS.mobile) return 'mobile';
  if (size < BREAKPOINTS.tablet) return 'tablet';
  return 'desktop';
}

export default getDeviceByViewport;
