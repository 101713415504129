// These common styles are used for Header and NewsFeedRow grid styles

export const mobileGridStyles = `
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-column-gap: 20px;
`;

export const tabletGridStyles = `
  display: grid;
  grid-template-columns: 8fr 2fr 2fr;
  grid-column-gap: 60px;
`;

export const desktopGridStyles = `
  display: grid;
  grid-template-columns: 5fr 2fr 2fr 3fr;
  grid-column-gap: 60px;
`;
