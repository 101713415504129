import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchCmsArticles } from 'server/api/cms-articles-api';
import media from 'utils/styles/media';
import colors from 'utils/styles/colors';
import letterSpacing from 'utils/styles/letterSpacing';
import NewsFeedRow from '../NewsFeedRow';

export const MORE_RECENT_ARTICLES_COUNT = 2;

const MoreRecentArticles = () => {
  const { articleSlug } = useParams();
  const [recentArticles, setRecentArticles] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchMoreRecent = async () => {
      try {
        const recentNewsArticles = await fetchCmsArticles(MORE_RECENT_ARTICLES_COUNT + 1, '');

        const moreRecentArticles = recentNewsArticles.articles.filter(
          (article) => article.slug !== articleSlug,
        );

        setRecentArticles(moreRecentArticles.slice(0, MORE_RECENT_ARTICLES_COUNT));
      } catch {
        setError(true);
      }
    };

    fetchMoreRecent();
  }, [articleSlug]);

  return (
    !error && (
      <MoreRecentArticlesWrapper>
        <MoreStoriesText data-testid="more-stories">More Stories</MoreStoriesText>
        {recentArticles &&
          recentArticles.map((recentArticle) => (
            <NewsFeedRow
              article={recentArticle}
              key={recentArticle.id}
              data-testid="news-feed-row"
            />
          ))}
        <ViewAllTextWrapper>
          <ViewAllText to="/news" data-testid="view-all">
            View All
          </ViewAllText>
        </ViewAllTextWrapper>
      </MoreRecentArticlesWrapper>
    )
  );
};

const MoreRecentArticlesWrapper = styled.div`
  margin-bottom: 60px;

  ${media['640']`
    margin-bottom: 140px;
  `}

  @media print {
    display: none;
  }
`;
const MoreStoriesText = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 40px;
  color: ${colors.black};

  ${media['640']`
    font-size: 24px;
    ${letterSpacing};
    margin-bottom: 80px;
  `}
`;
const ViewAllTextWrapper = styled.div`
  margin: 40px 0 80px;

  ${media['640']`
    margin: 80px 0 160px;
  `}
`;
const ViewAllText = styled(Link)`
  font-size: 16px;
  color: ${colors.gray};
  text-decoration: none;
  text-transform: capitalize;
  text-decoration: underline;

  ${media['640']`
    font-size: 24px;
    ${letterSpacing};
  `}
`;

export default MoreRecentArticles;
