import { css } from 'styled-components/macro';

export const breakpoints = [640, 768, 1024];

export default breakpoints.reduce(
  (mediaQueries, breakpoint) => ({
    ...mediaQueries,
    [breakpoint]: (...args) => css`
      @media (min-width: ${breakpoint / 16}em) {
        ${css(...args)}
      }
    `,
  }),
  {},
);
