import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import styled from 'styled-components';
import media from 'utils/styles/media';
import letterSpacing from 'utils/styles/letterSpacing';
import convertToEasternTime from 'utils/convertToEasternTime';
import colors from 'utils/styles/colors';

const ArticleHeader = ({ publishTime, subject, description }) => (
  <ArticleHeader.Wrapper>
    <ArticleHeader.Title data-testid="article-title">{subject}</ArticleHeader.Title>
    <ArticleHeader.Description data-testid="article-description">
      {description}
    </ArticleHeader.Description>
    <ArticleHeader.DateTime>
      <div data-testid="article-publish-date">
        {format(convertToEasternTime(publishTime), 'MMM d, yyyy')}
      </div>
      <div data-testid="article-publish-time">
        {`${format(convertToEasternTime(publishTime), 'h:mm')} ET`}
      </div>
    </ArticleHeader.DateTime>
  </ArticleHeader.Wrapper>
);

ArticleHeader.propTypes = {
  publishTime: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

ArticleHeader.Wrapper = styled.div``;
ArticleHeader.Title = styled.h1`
  margin: 50px 0 15px;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.black};
  ${letterSpacing};
  text-transform: uppercase;
  font-weight: normal;

  ${media['640']`
    margin: 80px 0 25px;
    font-size: 32px;
    line-height: 34px;
  `}
`;
ArticleHeader.Description = styled.p`
  margin: 15px 0;
  font-size: 16px;
  line-height: 18px;

  ${media['640']`
     margin: 25px 0;
     font-size: 24px;
     line-height: 26px;
     ${letterSpacing};
   `}
`;
ArticleHeader.DateTime = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  ${letterSpacing};
  margin: 15px 0;

  ${media['640']`
     font-size: 24px;
     margin: 25px 0;
   `}
`;

export default ArticleHeader;
