/**
 * [lockBodyScrolling]
 *  takes in the current scrollY position, and locks the background body in place maintaing that
 * position on unlock it removes that styling so the body continues to scroll from where it left off
 * @param  {number} scrollY  the scrollY position of the window on lock
 * @param  {boolean} isLocked  boolean telling to lock or unlock the body
 * ...(100, true) ->  locks the body at 100px height and prevents scrolling
 * ...(100, false)  -> unlocks the body,enabling scrolling and scrolling to height previous to lock
 */

export const lockBodyScrolling = (scrollY, isLocked) => {
  if (typeof scrollY !== 'number') {
    return;
  }
  if (isLocked) {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
    document.body.style.left = '0';
    document.body.style.right = '0';
  } else {
    document.body.style.position = 'inherit';
    window.scrollTo(0, scrollY);
  }
};

export default lockBodyScrolling;
