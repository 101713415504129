import root from 'window-or-global';

function getReleaseEnv() {
  const host = root.location && root.location.host;
  if (host === 'staging.goatgroup.com') return 'staging';
  if (host === 'www.goatgroup.com') return 'production';
  return 'development';
}

export const RELEASE_ENV = getReleaseEnv();
export const GOOGLE_TRACKING_ID = 'UA-169204630-1';
export const BUGSNAG_BROWSER_API_KEY = '26a59669df7a53a653f5faeda25404db';
export const MEDIA_KIT_URL =
  RELEASE_ENV === 'production'
    ? 'https://cms-cdn.goat.com/22d627060bd9-fac8-ee11-d671-0bd315a1.zip'
    : 'https://cms-cdn-staging.foodsworth.com/ac15bd515dbf-36ca-ee11-0771-08e13f73.zip';

window.RELEASE_ENV = RELEASE_ENV;
window.BUGSNAG_BROWSER_API_KEY = BUGSNAG_BROWSER_API_KEY;
window.MEDIA_KIT_URL = MEDIA_KIT_URL;
window.GOOGLE_TRACKING_ID = GOOGLE_TRACKING_ID;

function initializeGoogleAnalytics() {
  if (RELEASE_ENV === 'production') {
    window.ga =
      window.ga ||
      function (...args) {
        (window.ga.q = window.ga.q || []).push(args);
      };
    window.ga.l = +new Date();

    window.ga('create', window.GOOGLE_TRACKING_ID, 'auto');
    window.ga('send', 'pageview');

    const s = document.createElement('script');
    s.src = `https://www.google-analytics.com/analytics.js`;
    s.async = true;
    document.body.appendChild(s);
  } else {
    console.log('Google analytics is not initialized on non-production environments');
  }
}

initializeGoogleAnalytics();
