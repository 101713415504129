const supportsTouch = () => {
  try {
    if (window && window.document) {
      return 'ontouchstart' in document.documentElement;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export default supportsTouch;
