import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { GOATGroupLogo } from 'common/icons';
import ResizeContainer from 'utils/components/ResizeContainer';
import media from 'utils/styles/media';
import colors from 'utils/styles/colors';
import ZINDEX from 'utils/styles/zIndex';
import { desktopGridStyles } from 'utils/styles/gridColumns';
import DesktopHeaderNav from './DesktopHeaderNav';
import MobileHeaderNav from './MobileHeaderNav';

const Header = () => {
  const { pathname } = useLocation();
  const isOnNewsPath = pathname.includes('news');

  return (
    <HeaderWrapper isWhite={isOnNewsPath}>
      <GoatGroupWrapper>
        <Link to="/" data-testid="goat-group-logo">
          <GOATGroupLogo fill={isOnNewsPath ? colors.black : colors.white} />
        </Link>
      </GoatGroupWrapper>
      {/* Because we need to align the HeaderNav with the right most column
      of the NewsFeedRow grid styling, we need these empty divs */}
      <EmptyDivForDesktop />
      <EmptyDivForDesktop />
      <ResizeContainer desktop={<DesktopHeaderNav />} mobile={<MobileHeaderNav />} />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 30px 30px 10px;
  width: 100%;
  background-color: ${({ isWhite }) => (isWhite ? colors.white : colors.black)};
  z-index: ${ZINDEX.HEADER};

  ${media['640']`
    padding: 30px 60px 10px;
  `}

  ${media['1024']`
    ${desktopGridStyles};
  `}
`;
const GoatGroupWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
const EmptyDivForDesktop = styled.div`
  display: none;

  ${media['1024']`
   display: block;
  `}
`;

export default Header;
