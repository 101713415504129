import React from 'react';
import { useNProgress } from '@tanem/react-nprogress';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ZINDEX from 'utils/styles/zIndex';
import colors from 'utils/styles/colors';

const ProgressBar = ({ isLoading = false, invert = false }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isLoading,
  });

  return (
    <Container isFinished={isFinished} animationDuration={animationDuration}>
      <BarWrapper progress={progress} animationDuration={animationDuration} $invert={invert} />
    </Container>
  );
};

ProgressBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invert: PropTypes.bool,
};

ProgressBar.defaultProps = {
  invert: false,
};

const Container = styled.div`
  opacity: ${({ isFinished }) => (isFinished ? 0 : 1)};
  pointer-events: none;
  transition: ${({ animationDuration }) => `opacity ${animationDuration}ms linear`};
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${ZINDEX.PROGRESS_BAR};
  width: 100%;
`;
const BarWrapper = styled.div`
  background: ${({ $invert }) => ($invert ? colors.white : colors.black)};
  height: 1px;
  left: 0;
  margin-left: ${({ progress }) => `${(-1 + progress) * 100}%`};
  transition: ${({ animationDuration }) =>
    animationDuration && `margin-left ${animationDuration}ms linear`};
  width: 100%;
`;

export default ProgressBar;
