import { ALIAS, FLIGHT_CLUB, GOAT } from 'common/constants/brands';
import { ALIAS_IMAGES, FC_IMAGES, GOAT_IMAGES } from 'common/homepage/components/HomepageImages';

export const brandsImageIndexReducer = (state, action) => {
  switch (action.type) {
    case GOAT:
      return { ...state, [GOAT]: state[GOAT] === GOAT_IMAGES.length - 1 ? 0 : state[GOAT] + 1 };
    case FLIGHT_CLUB:
      return {
        ...state,
        [FLIGHT_CLUB]: state[FLIGHT_CLUB] === FC_IMAGES.length - 1 ? 0 : state[FLIGHT_CLUB] + 1,
      };
    case ALIAS:
      return { ...state, [ALIAS]: state[ALIAS] === ALIAS_IMAGES.length - 1 ? 0 : state[ALIAS] + 1 };
    default:
      return state;
  }
};
