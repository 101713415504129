// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';

/**
 * useMaxHeight: calculates the max height between 2 elements when window resizes
 * @param ref1: first ref to get height from
 * @param ref2: second ref to get height from
 * @returns {number} returns the larger height of the 2 elements
 */

const useMaxHeight = (ref1, ref2) => {
  const [height, setHeight] = useState(0);

  function calcHeight(firstRef, secondRef) {
    const ref1Styles = window.getComputedStyle(firstRef);
    const ref2Styles = window.getComputedStyle(secondRef);

    const ref1Margin = parseFloat(ref1Styles.marginTop) + parseFloat(ref1Styles.marginBottom);
    const ref2Margin = parseFloat(ref2Styles.marginTop) + parseFloat(ref2Styles.marginBottom);

    const titleHeight = Math.ceil(firstRef.offsetHeight + ref1Margin);
    const infoHeight = Math.ceil(secondRef.offsetHeight + ref2Margin);

    const maxHeight = Math.max(titleHeight, infoHeight);

    setHeight(maxHeight);
    return maxHeight;
  }

  useEffect(() => {
    function onResize() {
      calcHeight(ref1.current, ref2.current);
    }

    window.addEventListener('resize', onResize);

    calcHeight(ref1.current, ref2.current);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return height;
};

export default useMaxHeight;
